<template>
    <ProjectsList :isEmployee="isEmployee" :projects="this.projects" :is-loaded="!this.loading">
        <template v-if="this.error" v-slot:error :errorMsg="this.errorMsg">
            <md-list>
                <md-list-item>
                    <md-icon class="md-accent">error</md-icon>
                    <span class="md-list-item-text" v-html="errorMsg"></span>
                </md-list-item>
            </md-list>
        </template>
    </ProjectsList>
</template>

<script>
import ProjectsList from "../components/projects/ProjectsList"

export default {
    name: "Projects",
    props: {
        keycloak: {
            type: Object,
            required: true,
        }
    },
    components: {
        ProjectsList
    },
    data() {
        return {
            projects: null,
            loading: true,
            error: false,
            errorMsg: '',
        }
    },

    computed: {
        isEmployee: function () {
            if (!this.keycloak.realmAccess) {
                return
            }

            if (!this.keycloak.realmAccess.roles) {
                return
            }

            return this.keycloak.realmAccess.roles.includes("employee")
        },
    },

    mounted() {
        this.getProjects()
    },
    methods: {
        getProjects() {
            this.error = false
            this.loading = true
            this.$api.getProjects().then(response => {
                this.projects = response.data
                this.loading = false
            })
                .catch(error => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            this.errorMsg = this.get401ErrorMessage()
                        } else if (error.response.status === 403) {
                            this.errorMsg = this.get403ErrorMessage()
                        } else if (error.response.status === 404) {
                            this.errorMsg = this.get404ErrorMessage()
                        } else {
                            this.errorMsg = this.getCatchAllErrorMessage()
                        }
                    } else {
                        this.errorMsg = this.getCatchAllErrorMessage()
                    }
                    this.error = true
                    this.loading = false
                })
        },
        get401ErrorMessage() {
            return `Bei Ihrer Anfrage konnte die Autorisierung nicht überprüft werden.<br>
Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.<br>
Sollte dieser Fehler weiterhin auftreten, wenden Sie sich bitte an Ihren Ansprechpartner.`
        },
        get403ErrorMessage() {
            return `Sie haben nicht die Berechtigung, diesen Bereich einzusehen.`
        },
        get404ErrorMessage() {
            return `Mit Ihrem Account sind aktuell keine Projekte verknüpft.`
        },
        getCatchAllErrorMessage() {
            return `Produkte konnten nicht geladen werden.<br>
Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.<br>
Sollte dieser Fehler weiterhin auftreten, wenden Sie sich bitte an Ihren Ansprechpartner.`
        },
    }

}
</script>

<style lang="css" scoped>

</style>
