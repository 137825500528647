<template>
  <div id="app" class="page-container">
    <md-app :md-theme="template">
      <md-app-drawer
        :md-active.sync="menuVisible"
        md-persistent="mini"
        md-permanent="full"
        :md-fixed="true"
        :class="template"
        id="drawer"
      >
        <md-list>
          <md-list-item :href="this.homePage" target="_blank" class="logo-item">
            <img :src="this.logo" alt="Home" class="logo" />
            <md-tooltip md-direction="right">{{ this.homePage }}</md-tooltip>
          </md-list-item>
          <md-list-item :to="{ name: 'Projects' }">
            <md-icon class="notranslate white">home</md-icon>
            <md-tooltip md-direction="right">Overview</md-tooltip>
          </md-list-item>
          <md-list-item	v-if="isEmployee" :isEmployee="isEmployee" :href="this.tenantsUrl">
            <md-icon class="notranslate white">apartment</md-icon>
            <md-tooltip md-direction="right">Tenants</md-tooltip>
          </md-list-item>
          <md-list-item :href="createPasswordUrl()">
            <md-icon class="notranslate white">account_circle</md-icon>
            <md-tooltip md-direction="right">Change password</md-tooltip>
          </md-list-item>
          <md-list-item @click="logout">
            <md-icon class="notranslate white">logout</md-icon>
            <md-tooltip md-direction="right">Logout</md-tooltip>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content :class="template">
        <div :class="this.contentClass">
          <router-view :keycloak="this.keycloak" />
        </div>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  props: {
    keycloak: Object,
    template: String,
    homePage: String,
    tenantsUrl: String,
  },

  mounted: function () {
    this.setFavicon(this.template);
  },
  computed: {
    isEmployee: function () {
      if (!this.keycloak.realmAccess) {
        return false;
      }

      if (!this.keycloak.realmAccess.roles) {
        return false;
      }

      return this.keycloak.realmAccess.roles.includes("employee");
    },
    contentClass: function () {
      let grid = 70;
      if (this.isEmployee) {
        grid = 100;
      }

      return "md-layout-item md-layout md-gutter md-size-" + grid;
    },
    logo: function () {
      if (this.template === "vier") {
        return "/" + this.template + "/vier-bildmarke.svg";
      }
      if (this.template === "sc") {
        return "/" + this.template + "/favicon.ico";
      }
      return null;
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    logout: function () {
      this.keycloak.logout();
    },
    setFavicon(templateString) {
      const link = document.querySelector("[rel='icon']");
      link.setAttribute("href", "/" + templateString + "/favicon.ico");
    },
    createPasswordUrl: function () {
      let url = this.keycloak.createAccountUrl();
      return url.replace("/account", "/account/password");
    },
  },
  watch: {
    template(newVal) {
      this.setFavicon(newVal);
    },
    $route(to) {
      document.title = to.meta.title;
    },
  },
  data() {
    return {
      username: this.keycloak.tokenParsed.preferred_username,
      menuVisible: false,
    };
  },
};
</script>

<style lang="scss">
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("vier", (
  primary: $primaryMain, // The primary color of vier brand
  accent: $primaryMain // The secondary color of vier brand
));

@include md-register-theme("sc", (
  primary: $primaryMainSwisscom, // The primary color of swisscom brand
  accent: $primaryDarkSwisscom // The secondary color of swisscom brand
));

@import "~vue-material/dist/theme/all"; // Apply the theme

@font-face {
  font-family: Dinot;
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/DINOT-Regular.eot") format("eot"),
    url("./assets/fonts/DINOT-Regular.min.woff") format("woff");
}

@font-face {
  font-family: Dinot;
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/DINOT-Medium.woff") format("woff");
}

@font-face {
  font-family: Dinot;
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/DINOT-Bold.eot") format("eot"),
    url("./assets/fonts/DINOT-Bold.min.woff") format("woff");
}

html,
body,
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: Dinot, Arial, Helvetica, sans-serif;
  font-weight: 400;
  background-color: $neutral900;
  color: $textPrimary;
  height: 100vh;
}

.md-layout.md-gutter {
  margin: 0;
}

.md-app {
  height: 100%;
  background-color: $neutral900 !important;
}

.md-app-content {
  background-color: transparent !important;
}

.md-app-scroller {
  padding-left: 0 !important;
}

.md-drawer.md-theme-vier {
  background: $blueDark;
}
.md-drawer.md-theme-sc,
.md-drawer.md-theme-default.swisscom {
  background: linear-gradient(
    to bottom,
    $primaryMainSwisscom 0%,
    $primaryDarkSwisscom 100%
  );
}

.md-drawer {
  .md-list {
    align-items: center;
    background-color: transparent;
    .md-list-item-content,
    .md-list-item-router {
      height: 48px;
      width: 48px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .md-list-item-content:hover {
      background-color: $blue900;
    }
    .md-list-item-content > .md-icon:first-child,
    .md-list-item-content > .md-icon:last-child {
      margin: 0;
    }
    .logo-item {
      margin-bottom: 24px;
    }
    .logo {
      height: 40px !important;
      width: 40px;
      max-height: 40px;
      max-width: 40px;
    }
  }
}

#drawer {
  width: 64px !important;
  border-right: 0;
  border: 0;
  position: relative;
  transform: translateZ(0);
  padding: 16px 0;
  box-shadow: 0px 2px 1px 1px rgba(28, 50, 87, 0.3),
    0px 1px 1px 0px rgba(28, 50, 87, 0.24),
    0px 1px 3px 0px rgba(28, 50, 87, 0.22);
}

.md-list.bottom {
  bottom: 0px;
  position: absolute;
  width: inherit;
}

.md-select-menu {
  .md-list.md-theme-vier
    .md-list-item-container:not(.md-list-item-default):not(
      .md-list-item-expand
    ):not([disabled]):hover,
  .md-list.md-theme-sc
    .md-list-item-container:not(.md-list-item-default):not(
      .md-list-item-expand
    ):not([disabled]):hover {
    background-color: $neutral200 !important;
  }
}

.md-content {
  color: $textPrimary !important;
  border-radius: 4px;
}

.md-card {
  background-color: #fff;
  border-radius: 4px !important;
  box-shadow: $shadowPanel !important;
  margin: 16px;

  .md-subhead,
  .md-subheading,
  .md-headline {
    font-weight: 500;
    color: $blueDark;
    letter-spacing: 0.04em;
  }

  .md-card-header {
    border-bottom: 1px solid $neutral900;
    background-color: $neutral100;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px 16px;

    + .md-card-content {
      padding-top: 16px;
    }
  }

  .md-headline {
    font-size: $fontSize;
    text-transform: uppercase;
    margin: 0;
  }
}

.md-list {
  border-radius: 4px;
}

.md-field.no-line:after {
  height: 0;
}

.md-field.md-theme-vier:after {
  background-color: $secondaryMain;
}

.md-field.input-range {
  margin-bottom: 2px;
}
.md-field.input-range:before,
.md-field.input-range:after {
  height: 0;
  background-color: transparent;
}

.md-menu-content {
  box-shadow: $shadowDialog !important;
  border-radius: 4px !important;
}

.md-list.md-theme-default {
  border-radius: 4px;
}

.md-button.md-theme-default {
  border: 1px solid $secondaryMain;
  border-radius: 4px;
}

.md-button.md-raised:not([disabled]) {
  padding: 6px 12px;
  border-radius: 4px;
  border: 0;
  box-shadow: none !important;
}

.md-button:disabled {
  color: #fff;
  background-color: $textDisabled;
  padding: 6px 12px;
  border-radius: 4px;
  border: 0;
}

.md-button.btn-secondary {
  border-radius: 4px;
  border: 1px solid $secondaryMain;
}

.spinner-wrap {
  text-align: center;
  padding-bottom: 50px;
}

.md-table {
  table {
    table-layout: fixed;
  }
  .md-table-content {
    flex: auto;
  }
  .md-table-head-label {
    color: $textPrimary;
  }
  .md-table-cell-container a i,
  .md-table-cell-container a {
    font-weight: 500;
  }
  th,
  td {
    word-wrap: break-word;
  }
  .employee table {
    min-width: 1300px;
  }
  .first-column-bold {
    font-weight: 500;
  }
  .md-table-row:hover:not(.md-header-row) .md-table-cell {
    background-color: $neutral200 !important;
  }
  .table-row-no-hover:hover:not(.md-header-row) .md-table-cell {
    background: unset !important;
  }
}

#table-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
}

.md-tooltip {
  background-color: $secondaryMain !important;
  border-radius: 4px !important;
}

.md-icon.red {
  color: $red !important;
}

.md-icon.green {
  color: $green !important;
}

.md-icon.white {
  color: #fff !important;
}

.md-icon.md-theme-vier.md-icon-font,
.md-icon.md-theme-sc.md-icon-font {
  color: unset;
}

.action-container {
  padding: 0 16px 16px 0;
}

.md-snackbar {
  background-color: $secondaryMain !important;
  border-radius: 4px !important;
}
.md-snackbar.md-position-center {
  margin-bottom: 16px !important;
}
</style>
