<template>
  <div class="md-layout-item">
    <template
      v-if="this.isLoaded && (!this.projects || this.projects.length === 0)"
    >
      <md-card>
        <md-card-content>
          <slot name="error">
            Mit Ihrem Account sind aktuell keine Projekte verknüpft.
          </slot>
        </md-card-content>
      </md-card>
    </template>
    <template v-else>
      <ProjectsFilter
        v-if="this.projects && isEmployee"
        :isEmployee="isEmployee"
        :projects="this.projects"
        v-on:filter-change="setActiveFilter"
        v-on:search-change="setActiveSearch"
      />
      <ProjectResellerFilter
        v-if="this.projects && this.hasCustomerValue && !this.isEmployee"
        :isEmployee="isEmployee"
        :projects="this.projects"
        v-on:filter-change="setActiveFilter"
        v-on:search-change="setActiveSearch"
      />

      <div class="md-card md-table">
        <div class="md-card-header">
          <h1 class="md-headline">Overview</h1>
        </div>
        <div class="md-toolbar md-table-toolbar md-transparent md-elevation-0">
          <div
            class="md-field md-toolbar-section-end md-clearable no-line"
            v-show="isPaginationActive"
            style="border-bottom: none"
          >
            <ProjectsPaginator
              :projects-length.camel="filteredProjectsLength"
              v-on:set-start-end="setStartEnd"
            />
          </div>
        </div>
        <div
          class="md-content md-table-content md-scrollbar"
          v-show="isLoaded && this.projects"
        >
          <md-table
            v-if="!isEmployee"
            v-model="currentPageRows"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <!-- <md-table-cell md-label="ID" md-sort-by="id">{{ item.id }}</md-table-cell> -->

              <md-table-cell
                md-label="Produkt"
                key="product"
                md-sort-by="product"
              >
                {{ item.product }}
              </md-table-cell>

              <md-table-cell
                v-if="item.title"
                md-label="Projekt"
                key="title"
                md-sort-by="title"
              >
                {{ item.title }}
              </md-table-cell>

              <md-table-cell
                v-if="hasCustomerValue"
                md-label="Tenant"
                key="customer"
                md-sort-by="tenant_name"
              >
                <template v-if="item.customer">
                  {{ item.customer.name }}
                </template>
                <template v-else> - </template>
              </md-table-cell>

              <md-table-cell md-label="Status" key="status" md-sort-by="status">
                <md-icon v-if="item.status" class="notranslate green"
                  >check</md-icon
                >
                <md-icon v-else class="notranslate red">close</md-icon>
              </md-table-cell>

              <md-table-cell md-label="Link" key="link" md-sort-by="link">
                <a :href="item.link" target="_blank">
                  <md-icon class="notranslate md-button">
                    login
                    <md-tooltip md-direction="top">Zum Produkt</md-tooltip>
                  </md-icon>
                </a>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <md-table
            v-else
            v-model="currentPageRows"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="employee"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Produkt"
                key="product"
                md-sort-by="product"
              >
                {{ item.product }}
              </md-table-cell>

              <md-table-cell md-label="Projekt" key="title" md-sort-by="title">
                {{ item.title }}
              </md-table-cell>

              <md-table-cell
                md-label="Lokale ID"
                key="tenant_id"
                md-sort-by="tenant_id"
              >
                {{ item.tenant_id }}
              </md-table-cell>

              <md-table-cell
                md-label="Lokaler Name"
                key="tenant_name"
                md-sort-by="tenant_name"
              >
                {{ item.tenant_name }}
              </md-table-cell>

              <md-table-cell
                md-label="Tenant"
                key="customer"
                md-sort-by="tenant_name"
              >
                {{ item.customer.name }}
              </md-table-cell>

              <md-table-cell
                md-label="Datev ID"
                key="datev_id"
                md-sort-by="datev_id"
              >
                {{ item.datev_id }}
              </md-table-cell>

              <md-table-cell md-label="Status" key="status" md-sort-by="status">
                <md-icon v-if="item.status" class="notranslate green"
                  >check</md-icon
                >
                <md-icon v-else class="notranslate red">close</md-icon>
              </md-table-cell>

              <md-table-cell
                md-label="Projekt Typ"
                key="project_type"
                md-sort-by="project_type"
              >
                {{ item.project_type }}
              </md-table-cell>

              <md-table-cell
                md-label="KAM"
                key="architect"
                md-sort-by="architect"
              >
                {{ item.architect.initials }}
                <md-tooltip md-direction="top">{{
                  item.architect.name
                }}</md-tooltip>
              </md-table-cell>

              <md-table-cell md-label="System" key="system" md-sort-by="system">
                {{ item.system }}
              </md-table-cell>

              <md-table-cell md-label="Link" key="link" md-sort-by="link">
                <a :href="item.link" target="_blank">
                  <md-icon class="notranslate md-button">
                    login
                    <md-tooltip md-direction="top">Zum Produkt</md-tooltip>
                  </md-icon>
                </a>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <div v-show="!isLoaded" class="spinner-wrap">
          <md-progress-spinner
            :md-diameter="70"
            :md-stroke="7"
            md-mode="indeterminate"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectsFilter from "./ProjectsFilter";
import ProjectsPaginator from "./ProjectsPaginator";
import ProjectResellerFilter from "./ProjectResellerFilter";
import { firstBy } from "thenby";

export default {
  name: "CustomerProjectsList",
  components: {
    ProjectsFilter,
    ProjectsPaginator,
    ProjectResellerFilter,
  },
  props: {
    isEmployee: Boolean,
    projects: Array,
    isLoaded: Boolean,
  },

  data() {
    return {
      currentSort: "id",
      currentSortOrder: "asc",
      activeFilter: {
        // status: "true"
      },
      filterQueryParams: {},
      searchQueryParams: {},
      activeSearch: null,
      currentOffset: 0,
      totalRows: 1,
      perPage: 30,
      start: 0,
      end: 0,
      minLength: 5,
    };
  },

  methods: {
    customSort(value) {
      if (this.currentSort === "id") {
        return value;
      }
      return value.sort(firstBy(this.currentSort, this.currentSortOrder));
    },
    setStartEnd(start, end) {
      this.start = start;
      this.end = end;
    },
    setActiveFilter(filterObject) {
      let filters = {};
      let multiFilterKeys = ["project_type", "product"];
      for (const [key, value] of Object.entries(filterObject)) {
        if (multiFilterKeys.includes(key) && value.length !== 0) {
          filters[key] = value.join();
        } else if (!multiFilterKeys.includes(key) && value !== "") {
          filters[key] = value;
        }
      }

      this.filterQueryParams = filters;
      this.addQueryParams();

      this.activeFilter = filterObject;
    },
    addQueryParams() {
      let params = { ...this.filterQueryParams, ...this.searchQueryParams };
      let queryString = "";
      if (Object.keys(params).length !== 0) {
        queryString =
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
      }
      history.pushState({}, null, this.$route.path + queryString);
    },
    setActiveSearch(searchObject) {
      let searches = {};
      for (const [key, value] of Object.entries(searchObject)) {
        if (value !== "") {
          searches[key] = value;
        }
      }
      this.searchQueryParams = searches;
      this.addQueryParams();
      this.activeSearch = searchObject;
    },
    fieldFilter(rowItem, filterKeys, activeFilter) {
      if (filterKeys.length === 0) {
        return true;
      }

      if (this.currentSort === "id") {
        // this.activeFilter["status"] = 'true'
        // activeFilter["status"] = 'true'
        // if (!filterKeys.includes("status")) {
        //     filterKeys.push("status")
        // }
      }

      let returned = true;

      let multiFilterKeys = ["project_type", "product"];
      filterKeys.forEach((filterKey) => {
        let filterValue = activeFilter[filterKey];
        let item = rowItem[filterKey];

        if (filterKey === "customer") {
          if (item !== null && item.name !== filterValue) {
            returned = false;
          }
          if (filterValue === "unassigned" && item.name === null) {
            returned = true;
          }
        } else if (filterKey === "architect") {
          if (item !== null && item.initials !== filterValue) {
            returned = false;
          }
          if (filterValue === "unassigned" && item.initials === null) {
            returned = true;
          }
        } else if (filterKey === "status") {
          if (item !== (filterValue === "true")) {
            returned = false;
          }
        } else if (multiFilterKeys.includes(filterKey)) {
          if (filterValue.length !== 0) {
            let check = false;
            filterValue.forEach((ele) => {
              if (item === ele) {
                check = true;
              }
            });
            if (!check) {
              returned = false;
            }
          }
        } else {
          if (item !== filterValue) {
            returned = false;
          }
        }
      });
      return returned;
    },
    fieldSearch(rowItem, searchKeys, activeSearch) {
      if (searchKeys.length === 0) {
        return true;
      }

      let returned = true;
      searchKeys.forEach((searchKey) => {
        let search = activeSearch[searchKey];
        let item = rowItem[searchKey];

        if (typeof search === "number") {
          search = search.toString();
        }
        if (typeof item === "number") {
          item = item.toString();
        }
        if (item !== null) {
          if (searchKey === "tenant_id" || searchKey === "datev_id") {
            if (item.toUpperCase().indexOf(search.toUpperCase()) !== 0) {
              returned = false;
            }
          } else if (searchKey === "customer_uuid") {
            if (
              rowItem["customer"].uuid === null ||
              rowItem["customer"].uuid
                .toUpperCase()
                .indexOf(search.toUpperCase()) !== 0
            ) {
              returned = false;
            }
          } else {
            if (item.toUpperCase().indexOf(search.toUpperCase()) <= -1) {
              returned = false;
            }
          }
        } else {
          returned = false;
        }
      });

      return returned;
    },
  },
  watch: {},
  mounted() {},
  computed: {
    filterKeys() {
      if (this.activeFilter === null || this.activeFilter === undefined) {
        return [];
      }
      return Object.keys(this.activeFilter).filter(
        (key) =>
          this.activeFilter[key] !== null &&
          this.activeFilter[key] !== undefined &&
          this.activeFilter[key] !== ""
      );
    },
    searchKeys() {
      if (this.activeSearch === null || this.activeSearch === undefined) {
        return [];
      }
      return Object.keys(this.activeSearch).filter(
        (key) =>
          this.activeSearch[key] !== null &&
          this.activeSearch[key] !== undefined &&
          this.activeSearch[key] !== ""
      );
    },
    filteredProjects: function () {
      if (Array.isArray(this.projects)) {
        let filter = this.fieldFilter;
        let filterKeys = this.filterKeys;
        let activeFilter = this.activeFilter;

        let search = this.fieldSearch;
        let searchKeys = this.searchKeys;
        let activeSearch = this.activeSearch;
        return this.projects
          .filter(function (item) {
            return filter(item, filterKeys, activeFilter);
          })
          .filter(function (item) {
            return search(item, searchKeys, activeSearch);
          });
      }
      return [];
    },
    filteredProjectsLength() {
      if (Array.isArray(this.filteredProjects)) {
        return this.filteredProjects.length;
      }
      return 0;
    },
    sortedProjects: function () {
      if (Array.isArray(this.filteredProjects)) {
        const projects = this.filteredProjects;
        if (this.currentSort != "id") {
          return projects.sort(
            firstBy(this.currentSort, this.currentSortOrder)
          );
        }
        return projects.sort(
          firstBy("product").thenBy("project_type").thenBy("title")
        );
      }

      return [];
    },
    currentPageRows: {
      get: function () {
        if (this.isPaginationActive) {
          return this.sortedProjects.slice(this.start, this.end);
        }
        return this.sortedProjects;
      },
      set: function () {
        // not needed
      },
    },
    isPaginationActive() {
      return this.filteredProjectsLength >= this.minLength;
    },
    /**
     * hasCustomerValue checks if the requested projects have at least 1 customer information, if it exists, the table column is visible
     * @returns boolean - if filteredProjects have at least one customer value.
     * */
    hasCustomerValue() {
      return this.projects.some((project) => {
        return project.customer;
      });
    },
  },
};
</script>
