import Vue from "vue";
import VueRouter from "vue-router";
import Projects from "./views/Projects";
Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "Projects",
      component: Projects,
      props: true,
      meta: {
        auth: true,
        title: "Overview"
      }
    }
 ]
});
