import axios from "axios";

export default class Api {
  client = null;

  projects = null;
  lastUpdated = 0;

  cacheTime = 10 * 60 * 1000; // 10min 
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL
    });

    //Add a valid access token to every api request as a Authorization Header
    this.client.interceptors.request.use(
      config => {
          const token = localStorage.getItem("vue-token");
          if (token) {
              config.headers['Authorization'] = 'Bearer ' + token;
          }
          return config;
      },
      error => {
          Promise.reject(error)
      });

  }

  getProjects() {
    let d = new Date();
    let now = d.getTime();

    if (this.projects === null || now > this.lastUpdated + this.cacheTime) {
      let data = this.client
        .get("projects", {})
      this.projects = data;
      this.lastUpdated = now;
    }
    return this.projects;
  }

}
