import Vue from "vue";
import VueLogger from "vuejs-logger";
import Configuration from "./Configuration";

import App from "./App.vue";
import Keycloak from "keycloak-js";
import Api from "./http/api/api.js";
import router from "./router.js";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";

const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = false;

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

let initOptions = {
  url: Configuration.value("iamServerUrl"),
  realm: Configuration.value("iamServerRealm"),
  clientId: Configuration.value("oauthClientId"),
  onLoad: "login-required",
};

let keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .success((auth) => {
    if (!auth) {
      window.location.reload();
    }

    let tenantAPI = new Api(Configuration.value("customerapiUrl"));
    Vue.prototype.$api = tenantAPI;

    Vue.config.productionTip = false;
    Vue.use(VueLogger, options);
    Vue.use(VueMaterial);

    new Vue({
      props: ["keycloak"],
      router,
      render: (h) =>
        h(App, {
          props: {
            keycloak: keycloak,
            template: Configuration.value("portalStyle"),
            homePage: Configuration.value("homePage"),
            tenantsUrl: Configuration.value("tenantsUrl"),
          },
        }),
    }).$mount("#app");

    localStorage.setItem("vue-token", keycloak.token);

    setInterval(() => {
      keycloak
        .updateToken(5)
        .success((refreshed) => {
          if (!refreshed) {
            Vue.$log.debug(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
            return;
          }
          localStorage.setItem("vue-token", keycloak.token);
          Vue.$log.debug("Token refreshed: " + refreshed);
        })
        .error(() => {
          Vue.$log.error("Failed to refresh token");
        });
    }, 5000);
  })
  .error(() => {
    Vue.$log.error("Authenticated Failed");
  });
