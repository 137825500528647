<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-headline">Suche & Filter</div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label for="product">Produkt</label>
              <md-select
                v-model="activeFilter.product"
                name="product"
                id="product"
                multiple
              >
                <md-option
                  v-for="product in productFilter"
                  v-bind:key="product.value"
                  :value="product.value"
                  >{{ product.text }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item">
            <md-field>
              <label for="customer">Tenant</label>
              <md-select
                v-model="activeFilter.customer"
                name="customer"
                id="customer"
              >
                <md-option value=""></md-option>
                <md-option
                  v-for="customer in customerFilter"
                  v-bind:key="customer.value"
                  :value="customer.value"
                  >{{ customer.text }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item">
            <md-field>
              <label for="project">Projekt</label>
              <md-input v-model="activeSearch.title"></md-input>
            </md-field>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
const sortAlphabetically = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const bandA = a.text.toUpperCase();
  const bandB = b.text.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
};

const sortProducts = (a, b) => {
  const customOrder = {
    "4Com Multichannel ACD": 1,
    "4Com Outbound Manager": 2,
    "4Com Cockpit": 3,
    "4Com Customer Feedback Voice": 4,
    "4Com Messenger": 5,
  };

  // Use toUpperCase() to ignore character casing
  const bandA = a.text;
  const bandB = b.text;

  if (customOrder[bandA] && customOrder[bandB]) {
    return customOrder[bandA] - customOrder[bandB];
  }

  if (customOrder[bandA] && !customOrder[bandB]) {
    return -1;
  }

  if (!customOrder[bandA] && customOrder[bandB]) {
    return 1;
  }

  return sortAlphabetically(a, b);
};

export default {
  name: "ProjectResellerFilter",
  props: {
    isEmployee: Boolean,
    projects: {},
  },
  data() {
    return {
      activeFilter: {
        product: [],
        status: "",
        project_type: [],
        team: "",
        architect: "",
        customer: "",
      },
      activeSearch: {
        title: "",
        tenant_id: "",
        datev_id: "",
        tenant_name: "",
        customer_uuid: "",
        system: "",
      },
      timeoutKeyStrokes: null,
    };
  },
  computed: {
    productFilter() {
      return this.genFilter("product").sort(this.sortProducts);
    },
    statusFilter() {
      return this.genFilter("status").sort(this.sortAlphabetically);
    },
    projectTypeFilter() {
      return this.genFilter("project_type").sort(this.sortAlphabetically);
    },
    teamFilter() {
      return this.genFilter("team").sort(this.sortAlphabetically);
    },
    architectFilter() {
      return this.arFilter().sort(this.sortAlphabetically);
    },
    customerFilter() {
      return this.cuFilter().sort(this.sortAlphabetically);
    },
  },
  watch: {
    activeFilter: {
      deep: true,
      handler: function (filterObject) {
        this.$emit("filter-change", filterObject);
      },
    },
    activeSearch: {
      deep: true,
      handler: function (searchObject) {
        clearTimeout(this.timeoutKeyStrokes);
        this.timeoutKeyStrokes = setTimeout(() => {
          let updatedSearchObject = {};
          Object.assign(updatedSearchObject, searchObject);
          this.$emit("search-change", updatedSearchObject);
        }, 500);
      },
    },
  },
  mounted() {
    this.$log.debug(this.$route.query);

    // Object.assign(filters, this.$route.query)
    let multiFilterKeys = ["project_type", "product"];
    for (const [key] of Object.entries(this.activeFilter)) {
      if (this.$route.query[key] !== undefined) {
        if (multiFilterKeys.includes(key)) {
          this.activeFilter[key] = this.$route.query[key].split(",");
        } else {
          this.activeFilter[key] = this.$route.query[key];
        }
      }
    }
    for (const [key] of Object.entries(this.activeSearch)) {
      if (this.$route.query[key] !== undefined) {
        this.activeSearch[key] = this.$route.query[key];
      }
    }
  },
  methods: {
    sortAlphabetically,
    sortProducts,
    genFilter(key) {
      let keys = Object.keys(
        this.projects.reduce(
          (prev, current) => ({ ...prev, [current[key]]: true }),
          {}
        )
      );
      let options = [];
      for (var i = 0; i < keys.length; i++) {
        if (keys[i] != "null" && keys[i] != "") {
          options.push({ value: keys[i], text: keys[i] });
        }
      }
      return options;
    },

    arFilter() {
      let initials = [];
      let names = [];
      for (let i in this.projects) {
        let project = this.projects[i];
        if (
          project.architect.initials !== null &&
          !initials.includes(project.architect.initials)
        ) {
          initials.push(project.architect.initials);
          names.push(project.architect.name);
        }
      }

      let arr = [];

      for (var j in initials) {
        arr.push({ value: initials[j], text: initials[j], tooltip: names[j] });
      }

      return arr;
    },

    cuFilter() {
      let names = [];
      for (var i in this.projects) {
        let project = this.projects[i];
        if (
          project.customer &&
          project.customer.name &&
          !names.includes(project.customer.name)
        ) {
          names.push(project.customer.name);
        }
      }

      let arr = [];

      for (var j in names) {
        arr.push({ value: names[j], text: names[j] });
      }

      return arr;
    },

    onStatusChange(e) {
      var newBool = null;
      if (e !== null) {
        newBool = e === "true";
      }
      this.activeFilter.status = newBool;
    },
    onDatevIdChange(e) {
      var newDatevId = null;
      if (e !== null && e.length > 0) {
        newDatevId = parseInt(e, 10);
      }
      return (this.activeFilter.datev_id = newDatevId);
    },
  },
};
</script>
