<template>
    <div class="md-layout-item md-size-30 md-small-size-100">
        <md-field>
            <label for="item-per-page">Projekte pro Seite</label>
            <md-select id="item-per-page" v-model.number="itemsPerPage">
                <md-option value="5">5</md-option>
                <md-option value="10">10</md-option>
                <md-option value="50">50</md-option>
                <md-option value="100">100</md-option>
                <md-option value="400">400</md-option>
            </md-select>
        </md-field>
        <div>
            <span class="range">
                {{ this.start+1 }} - {{ this.computedEnd}} von {{this.projectsLength}}
            </span>
            <div class="prev-next-button-container">
                <md-button v-on:click="subOffset" :disabled="prevDisabled" class="navigation-button">
                    <md-icon class="notranslate">navigate_before</md-icon>
                </md-button>
                <md-button v-on:click="addOffset" :disabled="nextDisabled" class="navigation-button">
                    <md-icon class="notranslate">navigate_next</md-icon>
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProjectsPaginator",
        mounted() {
            this.setStartEnd()
        },
        props: {
            projectsLength: Number,
        },
        data() {
            return {
                itemsPerPage: 400,
                start: 0,
                end: 10,
                currentOffset: 0,
                awaitingClicks: false,
            }
        },
        computed: {
            maxPossibleOffset: function () {
                let maxPossibleOffset = Math.floor((this.projectsLength - 1) / this.itemsPerPage)
                if (maxPossibleOffset < 0) {
                    maxPossibleOffset = 0
                }
                return maxPossibleOffset
            },

            computedEnd: function () {
                if (this.end > this.projectsLength) {
                    return this.projectsLength
                }
                return this.end
            },
            nextDisabled: function () {
                return this.currentOffset === this.maxPossibleOffset
            },
            prevDisabled: function () {
                return this.currentOffset === 0
            }
        },
        methods: {
            addOffset: function () {
                if (this.currentOffset < this.maxPossibleOffset) {
                    this.currentOffset++
                    this.setStartEnd()
                }
            },
            subOffset: function () {
                if (this.currentOffset > 0) {
                    this.currentOffset--
                    this.setStartEnd()
                }
            },
            setStartEnd: function () {
                this.start = this.currentOffset * this.itemsPerPage
                this.end = this.start + this.itemsPerPage

                if (!this.awaitingClicks) {
                    setTimeout(() => {
                        this.$emit('set-start-end', this.start, this.end)
                        this.awaitingClicks = false
                    }, 600) // 1 sec delay
                }
                this.awaitingClicks = true
            },
            resetOffset: function () {
                this.currentOffset = 0
            }
        },
        watch: {
            projectsLength: function () {
                this.resetOffset()
                this.setStartEnd()
            },
            itemsPerPage: function (oldVal, newVal) {
                if (oldVal !== newVal) {
                    this.resetOffset()
                }
                this.setStartEnd()
            }
        }
    }
</script>

<style scoped>
    .md-icon {
        color: rgba(0, 0, 0, 0.26);
    }

    .md-toolbar.md-theme-default.md-transparent button:disabled .md-icon {
        color: rgba(0, 0, 0, 0.26);
    }

    .navigation-button, .navigation-button:disabled {
        border: none;
        background: transparent;
    }
    .range{
        width: 50%;
        float: left;
        padding-top: 8px;
    }
    .prev-next-button-container{
        width: 50%;
        float: left;
    }
    .prev-next-button-container > button{
        margin: 0;
        padding: 0;
        width: 50%;
        float: left;
        min-width: 0;
    }
</style>
