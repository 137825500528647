import dotenv from "dotenv";
dotenv.config();

export default class Configuration {
  static get CONFIG() {
    return {
      iamServerUrl: "$VUE_APP_IAM_SERVER_URL",
      iamServerRealm: "$VUE_APP_IAM_SERVER_REALM",
      oauthClientId: "$VUE_APP_OAUTH_CLIENT_ID",
      customerapiUrl: "$VUE_APP_CUSTOMER_API_URL",
      portalStyle: "$VUE_APP_PORTAL_STYLE",
      homePage: "$VUE_APP_HOME_PAGE",
      tenantsUrl: "$VUE_APP_TENANTS_URL",
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      window.console.log(`Configuration: There is no key named "${name}"`);
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      window.console.log(`Configuration: Value for "${name}" is not defined`);
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        window.console.log(
          `Configuration: Environment variable "${envName}" is not defined`
        );
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value;
    }
  }
}
